import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
// import microfrontendLayout from "./microfrontend-layout.html";
// import jsonRoutes from "./routes.json";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
// name is @fence-estimator/sspa-product-builder or @fence-estimator/sspa-plan
const buildRoute = (name: string) => {
  const pathStart = name.split("/")[1].replace(/^sspa-/, "");
  const [first, ...rest] = pathStart.split("-");

  const path = [first, ...rest.map(capitalizeFirstLetter)].join("");
  return {
    type: "route",
    path,
    routes: [
      {
        type: "application",
        name,
      },
    ],
    props: {
      rootPath: path,
    },
  };
};
const STATIC_ROUTE_FILE = {
  "imports": {
    "single-spa": "https://cdn.jsdelivr.net/npm/single-spa@5.9.0/lib/system/single-spa.min.js",
    "react": "https://cdn.jsdelivr.net/npm/react@17.0.2/umd/react.production.min.js",
    "react/jsx-runtime": "https://ga.system.jspm.io/npm:react@17.0.2/jsx-runtime.js",
    "object-assign": "https://ga.system.jspm.io/npm:object-assign@4.1.1/index.js",
    "history": "https://ga.system.jspm.io/npm:history@5.2.0/main.js",
    "react-dom": "https://cdn.jsdelivr.net/npm/react-dom@17.0.2/umd/react-dom.production.min.js",
    "react-router": "https://ga.system.jspm.io/npm:react-router@6.2.1/main.js",
    "react-router-dom": "https://ga.system.jspm.io/npm:react-router-dom@6.2.1/main.js",
    "@fence-estimator/sspa-product-builder": "https://simpallocdn.couleetech.network/wasabi/sspa-product-builder/master/fence-estimator-sspa-product-builder.js",
    "@fence-estimator/core-types": "https://simpallocdn.couleetech.network/wasabi/core-types/master/fence-estimator-react-core.js",
    "@fence-estimator/react-utils": "https://simpallocdn.couleetech.network/wasabi/react-utils/master/fence-estimator-react-core.js",
    "@fence-estimator/sspa-product-configuration": "https://simpallocdn.couleetech.network/wasabi/sspa-product-configuration/master/fence-estimator-sspa-product-configuration.js",
    "@fence-estimator/sspa-home": "https://simpallocdn.couleetech.network/wasabi/sspa-home/master/fence-estimator-home.js",
    "@fence-estimator/sspa-plan": "https://simpallocdn.couleetech.network/wasabi/sspa-plan/master/fence-estimator-plan.js",
    "@fence-estimator/sspa-projects": "https://simpallocdn.couleetech.network/wasabi/sspa-projects/master/fence-estimator-projects.js",
    "@fence-estimator/sspa-company": "https://simpallocdn.couleetech.network/wasabi/sspa-company/master/fence-estimator-company.js",
    "@fence-estimator/sspa-inventory": "https://simpallocdn.couleetech.network/wasabi/sspa-inventory/master/fence-estimator-inventory.js",
    "@fence-estimator/sspa-profile": "https://simpallocdn.couleetech.network/wasabi/sspa-profile/master/fence-estimator-profile.js",
    "@fence-estimator/core-utils": "https://simpallocdn.couleetech.network/wasabi/core-utils/master/fence-estimator-core-utils.js",
    "@fence-estimator/react-components": "https://simpallocdn.couleetech.network/wasabi/react-components/master/dist/fence-estimator-react-components.js",
    "@fence-estimator/sspa-global-documents": "https://simpallocdn.couleetech.network/wasabi/react-components/master/dist/fence-estimator-sspa-global-documents.js",
    
  }
}

const getCoreImportMap = async () => {
  /*try {
  const coreImportmap = (document.getElementById("core-importmap") as any)?.src;
    if (coreImportmap) {
      const res = await fetch(coreImportmap);
      return res.json();
    }
    const body = (document.getElementById("core-importmap") as any)?.innerHTML;
    return JSON.parse(body);
  } catch {} */
  return STATIC_ROUTE_FILE;
};

Promise.all([fetch("/routes.json"), getCoreImportMap()]).then(
  async ([res, coreimportMapRes]) => {
    const jsonRoutes = await res.json();
    const importMaps = coreimportMapRes;
    const dynamicRoutes = Object.keys(importMaps.imports)
      .filter((key) => key.includes("@fence-estimator/sspa"))
      .map(buildRoute);

    jsonRoutes.routes.push(
      ...dynamicRoutes.filter((dr) => {
        return jsonRoutes.routes.find((r) => r.path === dr.path) === undefined;
      })
    );
    const routes = constructRoutes(jsonRoutes);
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });

    applications.forEach(registerApplication);
    layoutEngine.activate();
    start();
  }
);
